import { proxy } from 'valtio'
import { Admin } from '~/modules/admin/Admin'

export type SortBy = 'uid' | 'code' | 'name' | 'email' | 'expiredAt' | 'createdAt'

export type AdditionSearchKey =
  | undefined
  | 'name'
  | 'uid'
  | 'email'
  | 'startedAt'
  | 'expiredAt'
  | 'createdAt'
  | 'status'

export type SortOrder = 'asc' | 'desc'
export const productStatusArr = ['trial', 'premium'] as const
export type ProductStatus = (typeof productStatusArr)[number]

export const adminStore = proxy({
  me: {} as Admin.User,
  productGroup: [] as Admin.ProductGroup[],
  users: [] as Admin.NormalUser[],
  admin: null as null | Admin.Admin,
  readonlyMode: false as boolean,
  // UI
  selectedUserId: '' as string,
  selectedAgent: '' as string,
  selectedProductGroup: '' as string,
  selectedProduct: '' as string,
  page: 0 as number,
  totalPage: 0 as number,
  total: 0 as number,
  pageSize: 100 as number,
  sortBy: undefined as undefined | SortBy,
  additionSearchKey: undefined as undefined | AdditionSearchKey,
  sortOrder: 'asc' as SortOrder,
  // additional params:
  expiredBefore: '' as string,
  expiredAfter: '' as string,
  subscribeAfter: '' as string,
  subscribeBefore: '' as string,
  createdAfter: '' as string,
  createdBefore: '' as string,
  kwName: '' as string,
  kwUid: '' as string,
  kwEmail: '' as string,
  productStatus: 'premium' as ProductStatus,

  // modal
  modalOpen: false as boolean,
  userScopeProduct: '' as string,
  currentUser: {} as Admin.NormalUser,
  currentUserSubscriptionHistory: [] as Admin.SubscriptionHistory[],
})

export const adminNotifyPushStore = proxy({
  selectedUserUids: [] as string[],
  selectedProducts: [] as string[],
  isNotifyMode: false as boolean,
  isWebImgResource: false as boolean,
  remoteWebImgUrl: '' as string,
  localImageUrl: '' as string,
  localImageFilename: '' as string,
  sendAll: false as boolean,
  logs: [] as Admin.LogItem[],
  logQueryMode: '' as string,
  logFrom: '' as string,
  logTo: '' as string,
  logsModalOpen: false as boolean,
  notificationTitle: '' as string,
  notificationShow: false as boolean,
})
